import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "activate_id" */ "../views/Dashboard.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/profile.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword`" */ "../views/Reset_Password.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/directlist",
    name: "directlist",
    component: () =>
      import(/* webpackChunkName: "directlist" */ "../views/Direct_List.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/downlinelist",
    name: "downlinelist",
    component: () =>
      import(
        /* webpackChunkName: "downlinelist" */ "../views/Downline_List.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/treeview",
    name: "treeview",
    component: () =>
      import(/* webpackChunkName: "treeview" */ "../views/Tree_View.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/monthbonus",
    name: "monthbonus",
    component: () =>
      import(/* webpackChunkName: "treeview" */ "../views/MonthBonus.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/ultimatebonus",
    name: "ultimatebonus",
    component: () =>
      import(
        /* webpackChunkName: "downlinelist" */ "../views/UltimateBonus.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/levelincome",
    name: "levelincome",
    component: () =>
      import(/* webpackChunkName: "levelincome" */ "../views/LevelIncome.vue"),
    meta: { authOnly: true },
  },
  // {
  //   path: "/signup",
  //   name: "SigninView",
  //   component: () =>
  //     import(/* webpackChunkName: "signin" */ "../views/auth/SigninView.vue"),
  //   meta: { show: false },
  // },
  {
    path: "/login",
    name: "LoginView",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
    meta: { guestOnly: false },
  },
  {
    path: "/forget",
    name: "ForgetView",
    component: () =>
      import(/* webpackChunkName: "forget" */ "../views/auth/ForgetView.vue"),
    meta: { guestOnly: true },
  },
  {
    path: "/otp",
    name: "OTPVerification",
    component: () =>
      import(
        /* webpackChunkName: "OTPVerification" */ "../views/auth/OTPVerification.vue"
      ),
    meta: { guestOnly: true },
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/auth/ResetPassword.vue"
      ),
    meta: { guestOnly: true },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/RegisterView.vue"
      ),

      meta: { authOnly: false }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn() {
  return localStorage.getItem("user_token");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
